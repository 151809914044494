export class SimpleContactInfo {
  appName: string;
  appId: string;

  constructor(appName: string, appId: string) {
    this.appName = appName;
    this.appId = appId;
  }
}

export class LinkContactInfo extends SimpleContactInfo
{
  appUrl: string;

  constructor(appName: string, appId: string, appUrl: string) {
    super(appName, appId);
    this.appUrl = appUrl;
  }
}

export class PersonalInfo
{
  name: string;
  birthDate: Date;
  linkedContacts: LinkContactInfo[];
  simpleContacts: SimpleContactInfo[];

  getAge(): number
  {
    const currentDate: Date = new Date();
    return currentDate.getFullYear() - this.birthDate.getFullYear();
  }

  constructor(name: string, birthDate: Date, linkedContacts: LinkContactInfo[], simpleContacts: SimpleContactInfo[]) {
    this.name = name;
    this.birthDate = birthDate;
    this.linkedContacts = linkedContacts;
    this.simpleContacts = simpleContacts;
  }


}
