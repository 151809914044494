import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {HomeComponent} from '../pages/home/home.component';
import {AboutMeComponent} from '../pages/aboutme/about-me.component';
import {PagenotfoundComponent} from '../pages/pagenotfound/pagenotfound/pagenotfound.component';
import {ProjectsComponent} from '../pages/projects/projects.component';
import {CiripitorComponent} from '../pages/projects/ciripitor/ciripitor.component';
import {TabelADRComponent} from '../pages/projects/tabel-adr/tabel-adr.component';

const routes: Routes =
  [
    {path: '', component: HomeComponent},
    {path: 'aboutme', component: AboutMeComponent},
    {path: 'projects', component: ProjectsComponent},
    {path: 'projects/Ciripitor', component: CiripitorComponent},
    {path: 'projects/tabel-adr', component: TabelADRComponent},
    {path: '**', component: PagenotfoundComponent},
  ];
const routerOptions: ExtraOptions =
  {
    anchorScrolling: 'enabled',
    useHash: false,
  };

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports:
  [RouterModule]
})
export class AppRoutingModule { }
