<div id="projects">
  <div [id]="project.descriptor" *ngFor="let project of loadProjects()">
    <a [routerLink]="project.descriptor" class="projectTitle">{{project.title}}</a>
    <br/>
    <span>Repository (GitHub/BitBucket): <a href="{{project.repository}}">Link</a></span>
    <p>{{project.description[lang]}}</p>
    <ng-template [ngIf]="lang==='ro'">
      <p>Facilitățile aplicației:</p>
    </ng-template>
    <ng-template [ngIf]="lang==='en'">
      <p>App's features:</p>
    </ng-template>
    <ul>
      <li *ngFor="let feature of project.features[lang]">
        {{feature}}
      </li>
    </ul>
    <img [src]="project.presentationImage" class="projectPresentationImage"/>
  </div>
</div>

<div id="minorProjects">
  <ng-template [ngIf]="lang==='ro'">
    <h1>Proiecte minore</h1>
    <p>Unele din proiectele mele nu sunt suficient de complexe pentru a le trece lângă cele de mai sus, așa că voi aduce aici câteva mențiuni speciale.</p>
  </ng-template>
  <ng-template [ngIf]="lang==='en'">
    <h1>Minor projects</h1>
    <p>Some of my projects aren't complex enough to be put along the ones above, and thus I will mention them here.</p>
  </ng-template>
  <div *ngFor="let project of loadMinorProjects()">
    <h2 class="projectTitle">{{project.title}}</h2>
    <p>{{project.description[lang]}}</p>
    <img src="{{project.presentationImage}}" class="projectPresentationImage"/>
  </div>
</div>
<div id="notes">
  <ng-template [ngIf]="lang==='ro'">
    <p>* - proiect mai vechi, fără pagină proprie sau fără poze.</p>
  </ng-template>
  <ng-template [ngIf]="lang==='en'">
    <p>* - older project, without own page or without pics.</p>
  </ng-template>
</div>
