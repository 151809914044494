import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ISectionable} from '../domain/interfaces/isectionable';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  @Input()
  lang = 'ro';
  buttonTexts: {[language: string]:
      {
        [text: string]: string,
      }} = {
    ro: {
      Home: 'Acasă',
      Aboutme: 'Despre mine',
      Projects: 'Proiecte',
      Studies: 'Studii',
      Interests: 'Interese',
      Skills: 'Competențe',
      Contests: 'Competiții și olimpiade',
      Professional: 'Experiență profesională',
      Portfolio: 'Portofoliu',
    },
    en: {
      Home: 'Home',
      Aboutme: 'About me',
      Projects: 'Projects',
      Studies: 'Studies',
      Interests: 'Interests',
      Skills: 'Skills',
      Contests: 'Contests and olympiads',
      Professional: 'Professional experience',
      Portfolio: 'Portfolio',
    },
  };
  dropdowns: string[] = ['projectsDropdown', 'aboutmeDropdown'];
  constructor()
  {
  }

  topDropdownLeave(id: string): void
  {
    if (id != null) {
    document.getElementById(id).style.visibility = 'hidden';
    }
    else
    {
      this.dropdowns.forEach((i: string) =>
      {
        document.getElementById(i).style.visibility = 'hidden';
      });
    }
  }

  ngOnInit(): void {
  }

  topDropdownOpen(id: string): void {
    this.topDropdownLeave(null);
    if (this.dropdowns.indexOf(id) > -1) {
      document.getElementById(id).style.visibility = 'visible';
    }
  }
}
