import {Component, Output} from '@angular/core';
import {RouterModule} from '@angular/router';
import {languageService} from '../language/language.service';
import {Translatable} from '../domain/interfaces/translatable';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements Translatable{
  title = 'Ștefănescu Petru Teodor';
  language = 'en';

  private resetLanguageButtonStyles(): void {
    languageService.getAcceptedLanguages.forEach(x =>
    {
      document.getElementById(x).classList.remove('selectedLanguage');
    });
  }

  updateLanguage(val: string): void {
    try {
      languageService.updateLanguage(val);
      this.language = val;
      this.resetLanguageButtonStyles();
      document.getElementById(val).classList.add('selectedLanguage');
    }
    catch (e) {
      alert(e.message);
    }
  }
}
