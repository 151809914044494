import { Component, OnInit } from '@angular/core';
import {languageService} from '../../../language/language.service';

@Component({
  selector: 'app-ciripitor',
  templateUrl: './ciripitor.component.html',
  styleUrls: ['./ciripitor.component.css']
})
export class CiripitorComponent implements OnInit {

  lang: string;
  constructor() { }

  ngOnInit(): void {
    this.lang = languageService.language;
    languageService.languageEmitter.subscribe((param) => this.lang = param);
  }

}
