import { Component, OnInit } from '@angular/core';
import {languageService} from '../../../language/language.service';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {
  lang = languageService.language;

  constructor() { }

  ngOnInit(): void {
    languageService.languageEmitter.subscribe((param: string) =>
    {
      this.lang = param;
    });
  }

}
