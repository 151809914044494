import {Component, Input, OnInit} from '@angular/core';
import {petruInfo} from '../../info/personalInfo';
import {languageService} from '../../language/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})


export class HomeComponent implements OnInit {

  age: number;
  @Input()
  lang = languageService.language;
  constructor() { }

  ngOnInit(): void {
    this.age = petruInfo.getAge();
    languageService.languageEmitter.subscribe((param: string) =>
    {
      this.lang = param;
    });
  }

}
