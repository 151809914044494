import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {InfoComponent} from '../info/info.component';
import {NavigationComponent} from '../navigation/navigation.component';
import {HomeComponent} from '../pages/home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {AboutMeComponent} from '../pages/aboutme/about-me.component';
import {PagenotfoundComponent} from '../pages/pagenotfound/pagenotfound/pagenotfound.component';
import {ProjectsComponent} from '../pages/projects/projects.component';
import {CiripitorComponent} from '../pages/projects/ciripitor/ciripitor.component';
import {TabelADRComponent} from '../pages/projects/tabel-adr/tabel-adr.component';

@NgModule({
  declarations: [
    AppComponent,
    InfoComponent,
    NavigationComponent,
    HomeComponent,
    AboutMeComponent,
    PagenotfoundComponent,
    ProjectsComponent,
    CiripitorComponent,
    TabelADRComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
