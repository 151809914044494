<ng-template [ngIf]="lang==='ro'">
  <p>Salut și bine ai venit pe pagina mea web.
    Mă numesc Petru, am {{age}} de ani, sunt din Suceava, și sunt student la Informatică.</p>
  <p>Fiind un membru al <a href="https://ro.wikipedia.org/wiki/Genera%C8%9Bia_Z">generației Z</a>, am fost de mic înconjurat de tehnologie, iar odată cu orele petrecute în jocuri precum <a href="https://en.wikipedia.org/wiki/Screamer_4x4">Screamer 4x4</a> și <a href="https://en.wikipedia.org/wiki/18_Wheels_of_Steel#18_Wheels_of_Steel:_Pedal_to_the_Metal_[30_Aug_2004]">18 Wheels of Steel: Pedal to the Metal</a> am învățat și cum se folosește un calculator.</p>
  <p>Odată cu școala gimnazială, în cadrul opționalului Prietenul Meu - Calculatorul, am fost învățați pentru prima dată un limbaj de programare, C++, de către domnul profesor <a href="https://sulitas.tk/index.html">Silviu Suliță</a>.
    Interfața neprietenoasă a mediului de dezvoltare Borland C++ (cea cu paleta de culori <span style="background-color: turquoise;"><a href="https://winworldpc.com/screenshot/c5a0e280-a1c3-974a-24c2-9011c3a4efbf" style="color: blue;">care arăta în stilul acesta</a></span>) nu m-a intimidat. În schimb, faptul că puteam să determin calculatorul să facă ce îmi doresc eu, m-a făcut să fiu interesat de programare.</p>
  <p>Dacă ești ca mine, sigur ai jucat măcar odată în viață un joc multiplayer.
    Între jocurile pe care le-am jucat eu se numără și <a href="https://www.sa-mp.com/">Grand Theft Auto: San Andreas Multiplayer</a>, un mod care permitea jocul în rețea.
    În acest mod, era posibilă hostarea și personalizarea serverului, pe baza unui limbaj de programare numit Pawn, care are o sintaxă asemănătoare cu C/C++.
    Am început prin a modifica scripturi disponibile pe internet, cum făcea toată lumea de altfel, dar similaritatea dintre limbajul Pawn și C++, m-a determinat să creez propriul gamemode (așa cum se numea scriptul principal al jocului) într-o vacanță de vară.
    Pawn este astfel primul meu limbaj de programare învățat pe cont propriu.</p>
  <p>În liceu, am învățat bazele algoritmicii și am participat pentru prima dată la competiții de programare, iar facultatea m-a învățat până acum o bună parte din lucrurile pe care voiam să le învăț dar nu credeam că voi reuși vreodată să le înțeleg, cum ar fi Assembly.</p>
  <p>Cât despre mine, îmi place să cred că am o cultură generală bună și sunt interesat de multe domenii, dar între aceste domenii, se remarcă istoria, geografia, mecanica și electronica.</p>
  <p>De mic, am mers pe bicicletă. La un moment dat, am ajuns la concluzia că bicicleta nu îmi oferă suficientă viteză și siguranță dacă trebuie să mă deplasez în trafic. Așa că, din 2017, sunt motociclist (junior, având categoria A1), iar din 2019, pot conduce vehicule cu un număr de două ori mai mare de roți, datorită categoriei B.</p>
  <p>Cum am zis mai sus, sunt interesat și de electronică. Nu am avut ocazia să realizez montaje, dar youtuberi precum
    <a href="https://www.youtube.com/user/greatscottlab">GreatScott!</a>,
    <a href="https://www.youtube.com/user/msadaghd">Electroboom</a>,
    <a href="https://www.youtube.com/channel/UCQak2_fXZ_9yXI5vB_Kd54g">DiodeGoneWild</a> și
    <a href="https://www.youtube.com/user/bigclivedotcom">BigClive</a>
    fac electronica să pară interesantă și amuzantă.</p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>Hello and welcome to my webpage!
  My name is Petru, I'm {{age}}. I was born in Suceava and I study Computer Science.</p>
  <p>Being a <a href="https://en.wikipedia.org/wiki/Generation_Z">zoomer</a>, I was surrounded since I was a child with technology, and along with many hours spent playing games like <a href="https://en.wikipedia.org/wiki/Screamer_4x4">Screamer 4x4</a> and <a href="https://en.wikipedia.org/wiki/18_Wheels_of_Steel#18_Wheels_of_Steel:_Pedal_to_the_Metal_[30_Aug_2004]">18 Wheels of Steel: Pedal to the Metal</a> I've learned how to use computers.</p>
  <p>Since secondary school, thanks to optional class "Prietenul meu - Calculatorul" (My Friend - The Computer), we were taught our first programming language, C++, by <a href="https://sulitas.tk/index.html">Silviu Suliță</a>, my teacher.
    The unfriendly GUI of the Borland C++ IDE (the one with <span style="background-color: turquoise;"><a href="https://winworldpc.com/screenshot/c5a0e280-a1c3-974a-24c2-9011c3a4efbf" style="color: blue;">this color scheme</a></span>) didn't discouraged me. Not only that, but the fact that I could make a computer do whatever I want it to do, made me interested in programming.</p>
  <p>If you're like me, then surely you played atleast once a multiplayer game.
    Among the games I played, <a href="https://www.sa-mp.com/">Grand Theft Auto: San Andreas Multiplayer</a> an multiplayer mode, was one of them.
    Here you could host and customize your server, using Pawn, a programming language that has a similar syntax to C/C++.
    I started modifying scripts found online, like everybody else did, but the similarities between Pawn and C++ determined me to create my own gamemode (as the main part of a server script was called) in a summer vacation.
    Pawn is thus my first self-taught programming language.</p>
  <p>In highschool, I've learnt the basics of algorithmics and I've participated for the first time in programming contests, and university taught me until now a good part of the things that I never thought I will understand, like Assembly.</p>
  <p>As of myself, I like to think that I have a good general knowledge and I'm interested in many subjects, like history, geography, mechanics and electronics.</p>
  <p>I ride the bike for a long time, since childhood.
    At a point in time, I've realised that the bike didn't offered me enough speed and road safety. And so, since 2017, I'm a (junior, since I have only A1 sub-category) biker, and since 2019, I can drive vehicles with double the wheels, having B category. </p>
  <p>As I said above, I'm interested in electronics too. I didn't have the opportunity to design and my my circuits, but youtubers like
    <a href="https://www.youtube.com/user/greatscottlab">GreatScott!</a>,
    <a href="https://www.youtube.com/user/msadaghd">Electroboom</a>,
    <a href="https://www.youtube.com/channel/UCQak2_fXZ_9yXI5vB_Kd54g">DiodeGoneWild</a> and
    <a href="https://www.youtube.com/user/bigclivedotcom">BigClive</a>
    make electronics like a fun and interesting domain. </p>
</ng-template>
