import { Component, OnInit } from '@angular/core';
import {languageService} from '../../../language/language.service';

@Component({
  selector: 'app-tabel-adr',
  templateUrl: './tabel-adr.component.html',
  styleUrls: ['./tabel-adr.component.css']
})
export class TabelADRComponent implements OnInit {

  lang: string;
  constructor() { }

  ngOnInit(): void {
    this.lang = languageService.language;
    languageService.languageEmitter.subscribe((param) => this.lang = param);
  }

}
