<div id="background">
  <nav id="languageBar">
    <ng-template [ngIf]="language==='ro'">
      Selectează limba:
    </ng-template>
    <ng-template [ngIf]="language==='en'">
      Select language:
    </ng-template>
    <img id="ro" class="languageButton" (click)="updateLanguage('ro')" alt="RO Flag" src="assets/images/ROFlag.png"/>
    <img id="en" class="selectedLanguage languageButton" (click)="updateLanguage('en')" alt="US Flag" src="assets/images/USFlag.jpg"/>
  </nav>
  <app-info></app-info>
  <app-navigation [lang]="language"></app-navigation>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
</div>
