<ng-template [ngIf]="language==='ro'">
  <h1>Despre mine</h1>
</ng-template>
<ng-template [ngIf]="language==='en'">
  <h1>About me</h1>
</ng-template>

  <div id="studies">
    <ng-template [ngIf]="language==='ro'">
      <h2>Studii</h2>
      <ol>
        <li>Școala Gimnazială Numărul 1 Suceava (2007-2015)</li>
        <li>Colegiul Național de Informatică ”Spiru Haret” Suceava - profil real, specializarea Matematică-Informatică Intensiv (2015-2019)</li>
        <li>Facultatea de Matematică și Informatică a Universității Babeș-Bolyai din Cluj-Napoca - specializarea Informatică Română (2019-prezent)</li>
      </ol>
    </ng-template>
    <ng-template [ngIf]="language==='en'">
      <h2>Studies</h2>
      <ol>
        <li>Secondary School 1 Suceava (2007-2015)</li>
        <li>National College of Computer Science ”Spiru Haret” Suceava - real profile, Math-Computer science Intensive (2015-2019)</li>
        <li>Faculty of Mathematics and Computer Science of Babeș-Bolyai University Cluj-Napoca - Computer Science in Romanian (2019-present)</li>
      </ol>
    </ng-template>
  </div>
  <div id="interests">
    <ng-template [ngIf]="language==='ro'">
      <h2>Interese</h2>
      <p>Câteva din domeniile mele de interes sunt istoria, geografia, electronica și mecanică.</p>
    </ng-template>
    <ng-template [ngIf]="language==='en'">
      <h2>Interests</h2>
      <p>Among my fields of interest we can outline: history, geography, electronics and mechanics.</p>
    </ng-template>
  </div>
  <div id="skills">
    <ng-template [ngIf]="language==='ro'">
      <h2>Competențe</h2>
      <div class="linguistics">
        <h3>Competențe lingvistice</h3>
        <ul>
          <li>Limba engleză - nivel mediu. La ultima evaluare a competențelor bazată pe Cadrul Comun European, am obținut calificativul maxim al examenului, B2.</li>
          <li>Limba franceză - nivel începător/mediu, pe baza autoevaluării.</li>
        </ul>
        <p>Jocuri online precum <a href="https://en.wikipedia.org/wiki/Dota_2">Dota2</a> și curiozitatea, m-au determinat să încerc să învăț și alfabetul chirilic. Привет, товарищ!</p>
      </div>
      <div class="digital">
        <h3>Competențe digitale</h3>
        <div class="coding">
          <h4>Programare</h4>
          <ol>
            <li>C++ - primul meu limbaj de programare.</li>
            Introducerea în acest limbaj de programare mi-a fost oferită în timpul școlii generale, folosind ”prietenosul” IDE, Borland C++.
            <li>Pawn</li>
            Am folosit Pawn pentru a putea crea propriile servere pentru jocul San Andreas Multiplayer.
            <li>C# - prima experiență competitivă cu interfață grafică.</li>
            <li>SQL - T-SQL mi se pare minunat</li>
            <li>Python - primul limbaj de programare învățat în facultate și unul din preferatele mele.</li>
            <li>x86 Assembly - atât cursul de ASC, cât și <a href="https://www.youtube.com/user/eaterbc">Ben Eater</a> m-au ajutat să înțeleg cum funcționează un procesor. </li>
            <li>Java - cu toate că la început nu mi-a plăcut, am ajuns să înțeleg cum trebuie gândită o aplicație Java</li>
            <li>HTML, CSS, JavaScript și PHP - învățate în mod special pentru a putea realiza acest webite, iar CSS m-a ajutat și în dezvoltarea aplicației Ciripitor. Facultatea m-a ajutat și ea.</li>
          </ol>
        </div>
        <div class="multimedia">
          <h4>Multimedia</h4>
          <ol>
            <li>Photoshop</li>
            <li>Sony Vegas</li>
          </ol>
        </div>
        <div class="other">
          <h4>Altele</h4>
          <p>Am folosit în trecut API precum Unity pentru C#, JavaFX pentru Java, Angular pentru crearea acestei pagini web și Qt pentru C++.</p>
          <p>Pentru crearea acestui website am folosit AngularCLI, dar în anumite proiecte școlare am folosit și React.</p>
          <p>Pot folosi Git pentru a versiona aplicațiile.</p>
          <p>Pot utiliza un sistem de operare din familia Ubuntu (atât Desktop, cât și Server).</p>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="language==='en'">
      <h2>Skills</h2>
      <div class="linguistics">
        <h3>Linguistic skills</h3>
        <ul>
          <li>English - intermediate. Last Common European Framework evaluation brought me the maximum qualification level, B2.</li>
          <li>French - beginner/intermediate, based on self assessment.</li>
        </ul>
        <p>Online games like <a href="https://en.wikipedia.org/wiki/Dota_2">Dota2</a> and curiosity, made me try to learn cyrillic script. Привет, товарищ!</p>
      </div>
      <div class="digital">
        <h3>Digital skills</h3>
        <div class="coding">
          <h4>Coding</h4>
          <ol>
            <li>C++ - my first programming language</li>
            The introduction in this programming language was offered during my secondary school, using the "friendly" IDE, Borland C++.
            <li>Pawn</li>
            I used Pawn to create my own San Andreas Multiplayer servers.
            <li>C# - my first competitive experice having to deal with a GUI.</li>
            <li>SQL - T-SQL is awesome!</li>
            <li>Python - the first programming language taught in uni and one of my favorites.</li>
            <li>x86 Assembly - both CSA and <a href="https://www.youtube.com/user/eaterbc">Ben Eater</a> helped me learn how a CPU works.</li>
            <li>Java - although I didn't have such an appeal for Java, I learnt how a Java app should be conceived.</li>
            <li>HTML, CSS, JavaScript and PHP - learned especially to create this website, and CSS helped me to design Ciripitor app too. Uni helped with this too, for sure.</li>
          </ol>
        </div>
        <div class="multimedia">
          <h4>Multimedia</h4>
          <ol>
            <li>Photoshop</li>
            <li>Sony Vegas</li>
          </ol>
        </div>
        <div class="other">
          <h4>Other</h4>
          <p>I used in the past APIs like Unity for C#, JavaFX for Java, Angular for creating this website and Qt for C++.</p>
          <p>To make this website, I've used AngularCLI, but in some school projects I've used React too.</p>
          <p>I can use Git to version control my apps.</p>
          <p>I can use OSes from Ubuntu family (Desktop and Server).</p>
        </div>
      </div>
    </ng-template>
  </div>
  <div id="contests">
    <ng-template [ngIf]="language==='ro'">
      <h2>Competiții și olimpiade</h2>
      <div class="C#">
        <h3>Olimpiada de TIC - secțiunea C#</h3>
        <ul>
          <li>Călărași 2018 - Locul 1 la etapa județeană, mențiune la etapa națională.</li>
          <li>Arad 2019 - Locul 1 la etapa județeană, medalie de bronz la etapa națională.</li>
        </ul>
      </div>
      <div class="openingopportunities">
        <h3>Opening Opportunities by Techsoup</h3>
        <p>Pe principiul descris de <a href="https://ro.wikipedia.org/wiki/Efectul_fluturelui">efectul fluturelui</a>, Opening Opportunities mi-a schimbat viața. Pe de o parte, am lucrat în echipă pentru prima dată la o aplicație. Pe de altă parte, introducerea în C# realizată aici, și cunoștințele dobândite și fructificate în olimpiada de C#, m-au ajutat să studiez la facultatea dorită.</p>
        <ul>
          <li>2016
            <p>Împreună cu alți 5 colegi de clasă, am dezvoltat un joc de noroc tip păcănea folosind Unity Engine.</p></li>
          <li>2017
            <p>Probabil primul proiect în echipă realizat cu persoane pe care nu am avut ocazia să le întâlnesc vreodată, ediția din 2017 a mentoratului s-a remarcat pentru mine prin accentul pus pe lucrul în echipă.</p></li>
        </ul>
      </div>
    </ng-template>
    <ng-template [ngIf]="language==='en'">
      <h2>Contests and olympiads</h2>
      <div class="C#">
        <h3>IT&C Olympiad - C#</h3>
        <ul>
          <li>Călărași 2018 - 1st place at county stage, mention at national stage.</li>
          <li>Arad 2019 - 1st place at county stage, bronze medal at national stage.</li>
        </ul>
      </div>
      <div class="openingopportunities">
        <h3>Opening Opportunities by Techsoup</h3>
        <p>According to <a href="https://en.wikipedia.org/wiki/Butterfly_effect">butterfly effect</a>, Opening Opportunities changed my life. To begin with, I worked for the first time to develop an app with a team. On the other hand, the introduction in C# done with this occasion, and the acquired knowledge and fructified in the C# olympiad, helped me to study at the desired university.</p>
        <ul>
          <li>2016
            <p>Among with other 5 class colleagues, we developed a slot-machine game using Unity Engine.</p></li>
          <li>2017
            <p>Probably the first team project done remotely, with people I didn't have the opportunity to ever meet, the 2017 edition of the mentoring project is memorable to me because of the accent that was put on team work.</p></li>
        </ul>
      </div>
    </ng-template>
  </div>
  <div id="professional">
    <ng-template [ngIf]="language==='ro'">
      <h1>Experiență profesională</h1>
      <p>Până acum, am fost angajat ca:</p>
      <ul>
        <li>Livrator-încasator la S.C. Delivery Logistics S.R.L. (aka Foodpanda) în Suceava în perioada iunie-august 2020.
          <p>Primul meu loc de muncă, în care am avut ocazia să îmi îmbunătățesc abilitățile de comunicare, și capacitatea de a lucra sub presiune.</p>
        </li>
        <li>
          Stagiar în departamentul ASE (Application Software Engineering) - UI/UX la NTT Data România prin programul PEX (Prin EXcelenta) al UE.
          <p>Pe scurt, am contribuit împreună cu alți 5 colegi de echipă și cu un tutore la un proiect, RentInsider, care are doi clienți: unul web și unul pe mobile.
          <p>Aici am lucrat pentru prima dată într-o echipă în mod profesional, exclusiv folosind versionare Git, rezolvare bazată pe task-uri de pe GitHub a cerințelor, deploy pe Firebase a unui proiect Angular și pe Android a unui proiect Ionic.</p>
          <p>Pe lângă îmbunătățitul interfeței grafice și a experienței utilizatorului într-o aplicație care a fost începută de participanții anteriori ai programului, m-am ocupat de asemenea de aspecte de backend (cum se tratează erorile venite de la baza de date Firebase de exemplu), cât și de refactorizare a unor părți din cod.
          </p>
        </li>
      </ul>
    </ng-template>
    <ng-template [ngIf]="language==='en'">
      <h1>Professional experience</h1>
      <p>Until now, I've been employed as:</p>
      <ul>
        <li>Delivery-cashier at S.C. Delivery Logistics S.R.L. (aka Foodpanda) in Suceava during june-august 2020.
          <p>My first ever workplace, where I had the opportunity to improve the communication skills, and the ability to work under pressure.</p>
        </li>
        <li>
          Intern in ASE department (Application Software Engineering) - UI/UX at NTT Data Romania through UE's PEX (Prin EXcelenta) project.
          <p>In a nutshell, I've contributed along with 5 other teammates and a tutor to a project, RentInsider, that has two clients: a web client and a mobile one.</p>
          <p>Here I've worked for the first time in a team in a professional manner, using only Git for versioning, solving tasks given on GitHub, deploy on Firebase of an Angular project and on Android of an Ionic project.</p>
          <p>Beside improving GUI and users' experience in an application that was started by previous attendees, I've also worked on a couple of backend things (how backend errors are handled for example), but also refactoring code.</p>
        </li>
      </ul>
    </ng-template>
  </div>
  <div id="portfolio">
    <ng-template [ngIf]="language==='ro'">
      <h1>Portofoliu</h1>
      <p>Aici se află câteva dintre proiectele mele</p>
      <ol>
        <li><a routerLink="projects/Ciripitor">Ciripitor</a> - o rețea de socializare</li>
        <li><a routerLink="projects/TabelADR/">Tabel ADR</a> - o unealtă care poate ușura viața șoferilor profesioniști care transportă mărfuri periculoase</li>
      </ol>
    </ng-template>
    <ng-template [ngIf]="language==='en'">
      <h1>Portfolio</h1>
      <p>Here you can find some of my projects:</p>
      <ol>
        <li><a routerLink="/projects/Ciripitor">Ciripitor</a> - a social network</li>
        <li><a routerLink="/projects/tabel-adr">ADR Table</a> - a tool that can ease the life of professional drivers that carry dangerous goods.</li>
      </ol>
    </ng-template>
  </div>
