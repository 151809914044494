import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  path: string;
  constructor(path: string)
  {
    this.path = path;
  }
  private getContent(): string
  {
    let retVal: string;
    const rawFile = new XMLHttpRequest();
    rawFile.open('GET', this.path, false);
    rawFile.onreadystatechange = () =>
    {
      retVal = rawFile.responseText;
    };
    rawFile.send(null);
    return retVal;
  }
  public getProjects(): Array<Project>
  {
    const projects: Array<Project> = [];
    const text = this.getContent();
    const jsonResult = JSON.parse(text);
    for (const item of jsonResult.projects)
    {
      const title = item.title;
      const descriptor = item.descriptor;
      const presentationImage = item.presentationImage;
      const description = item.description;
      const features = item.features;
      const repository = item.repository;
      projects.push(new Project(title, descriptor, presentationImage, description, features, repository));
    }
    return projects;
  }
  public getMinorProjects(): Array<MinorProject>
  {
    const projects: Array<MinorProject> = [];
    const text = this.getContent();
    const jsonResult = JSON.parse(text);
    for (const item of jsonResult.minorProjects)
    {
      const title = item.title;
      const presentationImage = item.presentationImage;
      const description = item.description;
      projects.push(new MinorProject(title, description, presentationImage));
    }
    return projects;
  }
}

class MinorProject {
  title: string;
  description: { ro: string, en: string };
  presentationImage: string;

  constructor(title: string, description: { ro: string; en: string }, presentationImage: string) {
    this.title = title;
    this.description = description;
    this.presentationImage = presentationImage;
  }
}

export class Project extends MinorProject {
  descriptor: string;
  features: {ro: string[], en: string[]};
  repository: string;


  // tslint:disable-next-line:max-line-length
  constructor(title: string, descriptor: string, presentationImage: string, description: { ro: string; en: string }, features: { ro: string[]; en: string[] }, repository: string) {
    super(title, description, presentationImage);
    this.descriptor = descriptor;
    this.features = features;
    this.repository = repository;
  }
}
