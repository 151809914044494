import {EventEmitter, Injectable} from '@angular/core';
import {Translatable} from '../domain/interfaces/translatable';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements Translatable{

  language = 'en';
  languageEmitter: EventEmitter<string> = new EventEmitter<string>();
  private acceptedLanguages: string[] = ['ro', 'en'];
  updateLanguage(lang: string): void {
    if (this.acceptedLanguages.indexOf(lang) > -1) {
      this.language = lang;
      this.languageEmitter.emit(lang);
    }
    else {
      throw new DOMException('No such language!');
    }
  }

  get getAcceptedLanguages(): string[]
  {
    return this.acceptedLanguages;
  }


  constructor() {
    this.languageEmitter.emit('ro');
  }
}

export const languageService: LanguageService = new LanguageService();
