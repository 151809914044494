<h1>Ciripitor</h1>
<ng-template [ngIf]="lang==='ro'">
  <p>Denumirea aplicației este o traducere mot-à-mot a cuvântului ,,Twitter”.
    Am considerat că trebuie să aleg o denumire pentru aceast proiect, deoarece este primul proiect de-al meu în care design-ul chiar se punctează, și nu este ceva de fațadă mai degrabă, cum este în cazul Olimpiadei de TIC.
    Alegerea unei denumiri m-a ajutat să îmi fixez atât paleta de culori, cât și alte aspecte de design, precum fonturile și imaginile complementare.</p>
  <p>Ca să pot vizualiza mai bine evoluția în design, îmi place să compar interfețele pentru autentificare, înainte și după modificările de design.</p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>
    App's name is a word-by-word translation of the word "Twitter".
    I've come to the conclusion that I have to choose a name for this project, because it is my first project where the design really counts, not a superficial criteria, like IT&C Olympiad.
    Choosing a name helped me fix the color scheme, and other design-related aspects, like fonts and complementary images.
  </p>
  <p>To better observe the design evolution, I like to compare the login interfaces, before and after the design overhaul.</p>
</ng-template>

  <span>
    <img src="assets/images/Ciripitor/old/Login.png" alt="Interfața veche"/>
    <img src="assets/images/Ciripitor/Login.png" alt="Interfața nouă"/>
  </span>

<ng-template [ngIf]="lang==='ro'">
  <p>Până la acest proiect, singurele baze de date folosit au fost MSAccess/SQL Server din C#.
    Spre deosebire de aceste situații, am folosit de data aceasta PostgreSQL împreună cu Java pentru persistența datelor.</p>
  <p>De asemenea, am aplicat cunoștințele învățate atât în competițiile în care se cerea stocarea datelor în baza de date, cât și din ce am învățat la cursul de baze de date, pentru a crea o bază de date cât de cât normalizată.</p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>Until this project, I only have used MSAccess/SQL Server databases from C#.
  Unlike the aforementioned situations, I did use now PostgreSQL together with Java for data persistency.</p>
  <p>Also, I have applied the knowledge acquired during contests where database storage was a must, and also the knowledge acquired from the database course, to create a relatively normalised database.</p>
</ng-template>

<img src="assets/images/Ciripitor/DB%20scheme.png" alt="Schema bazei de date"/>

<ng-template [ngIf]="lang==='ro'">
  <p>De asemenea, pe partea de backend, toate datele care pot identifica o persoană, cu excepția id-ului, sunt criptate, folosind un salt cerut de algoritmul de criptate, care este de fapt un șir de caractere ales de mine, și criptat cu algoritmul de hashing SHA-1.
    Apoi, folosind librăriile built-in ale Java, am criptat fiecare câmp folosind algoritmul SHA-256, obținând astfel șiruri de 256 de biți.
    Dar pentru că am dorit să pot demonstra ușor că datele sunt criptate, am ales să le stochez în baza de date sub o formă mai ușor de citit pentru om decât șiruri de 0 și 1.
    Am observat că cea mai bună metodă pentru a realiza asta, este de a interpreta biții din rezultat ca un număr în baza 64.
    Pentru că această bază de numerație reprezintă numerele folosind și caractere nenumerice, am hotărât să stochez datele în baza de date sub formă de șiruri de caractere.
    De aceea, în diagrama bazelor de date, tabelul Users are aproape toate câmpurile varchar.
  </p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>
    On the backend, all data that identify a person, excepting the id, are encrypted, using a salt required by the encrypting algorithm, which is actually a string I picked myself, and hashed using SHA-1.
  Then, using the built-in Java libraries, I encrypted each field using SHA-256 algorithm, obtaining strings of 256 bits.
  But to demonstrate easily that the data is encrypted, I choose to store it under a easier-to-read for a human form, rather than strings of 0s and 1s.
  I realised that the best way to do this, is to represent the resulted bits as a number in the base 64.
  Because this numeration base uses both digits and letters, I decided to store the data in the database as text.
  That's why in the database diagram, the Users table has almost all columns of type varchar.
  </p>
</ng-template>

<img src="assets/images/Ciripitor/Encrypted%20db.png" alt="Encrypted database"/>

<ng-template [ngIf]="lang==='ro'">
  <p>Alte cerințe ale proiectului au fost administrarea evenimentelor și notificarea utilizatorilor privind apropierea unor evenimente la care s-au abonat.
  Acest lucru l-am făcut folosind un obiect de tip Event Handler, care pentru fiecare utilizator autentificat, încarcă evenimentele la care el participă, și folosind șablonul Observer, notifică fereastra principală de fiecare dată când un eveniment se află la un anumit interval de timp față de data de început.
  Aceste intervale le-am specificat în aplicație, după cum se vede mai jos.
  </p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>
    Other project requirements were event administration and user notifications regarding the coming up of events that they joined.
    I've done this through an Event Handler object, that loads each logged in user's events, and using Observer pattern, notifies the main window about an event that is about to start, at some intervals.
    These intervals are defined in the app, as shown below.
  </p>
</ng-template>

<span>
  <img src="assets/images/Ciripitor/Events%20handler.png" alt="Events Handler structure">
  <img src="assets/images/Ciripitor/Event%20details.png" alt="Event details">
  <img src="assets/images/Ciripitor/Notifications.png" alt="Notifications popup">
  <img src="assets/images/Ciripitor/Private%20profile.png" alt="User's private profile">
</span>

<ng-template [ngIf]="lang==='ro'">
  <p>
    Utilizatorii își pot verifica istoricul activităților legate de prietenii și de mesaje
  </p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>
    Users can see the activity log regarding friendships and messages
  </p>
</ng-template>

<span>
  <img src="assets/images/Ciripitor/Activities%20Report%20Window.png" alt="Activities report window">
  <iframe width="80%" height="400px" src="assets/images/Ciripitor/Example%20activity%20report.pdf"></iframe>
</span>

<ng-template [ngIf]="lang==='ro'">
  <p>
    Dacă se dorește căutarea unui alt utilizator, este suficient să facem o căutare parțială case-insensitive după username.
    Un exemplu de rezultat al căutării este mai jos:
  </p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>
    If we want to search another user, we simply have to do a partial case-insensitive search for username.
    A search example is shown below:
  </p>
</ng-template>

<img src="assets/images/Ciripitor/Home%20-%20Other%20profile.png" alt="Other user's profile">

<ng-template [ngIf]="lang==='ro'">
  <p>
    În această aplicație se face o distincție între profilul privat al unui utilizator, care memorează doar informațiile relevante utilizatorului autentificat, și profilul public, care nu conține informații confidențiale ale utilizatorului căutat.
  </p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>
    In this app, there is a clear separation between a user's private profile, that only holds relevant info about the authenticated user, and the public profile, that doesn't contain confidential info about searched user.
  </p>
</ng-template>
