<div (mouseleave)="topDropdownLeave(null)">
  <nav class="navigation">
    <button class="navigationButton" (mouseenter)="topDropdownOpen(null)" routerLink="" routerLinkActive="activeNavigationButton">{{buttonTexts[lang].Home}}</button>
    <button class="navigationButton" (mouseenter)="topDropdownOpen('aboutmeDropdown')" routerLink="aboutme">{{buttonTexts[lang].Aboutme}}</button>
    <button class="navigationButton" routerLink="projects" (mouseenter)="topDropdownOpen('projectsDropdown')">{{buttonTexts[lang].Projects}}</button>
  </nav>
  <div style="display: flex; flex-direction: row;">
    <div id="aboutmeDropdown" class="topMenuDropdown" (mouseleave)="topDropdownLeave('aboutmeDropdown')">
      <button class="topMenuDropdownButton" routerLink="aboutme" fragment="studies">{{buttonTexts[lang].Studies}}</button>
      <button class="topMenuDropdownButton" routerLink="aboutme" fragment="interests">{{buttonTexts[lang].Interests}}</button>
      <button class="topMenuDropdownButton" routerLink="aboutme" fragment="skills">{{buttonTexts[lang].Skills}}</button>
      <button class="topMenuDropdownButton" routerLink="aboutme" fragment="contests">{{buttonTexts[lang].Contests}}</button>
      <button class="topMenuDropdownButton" routerLink="aboutme" fragment="professional">{{buttonTexts[lang].Professional}}</button>
      <button class="topMenuDropdownButton" routerLink="aboutme" fragment="portfolio">{{buttonTexts[lang].Portfolio}}</button>
    </div>
    <div id="projectsDropdown" class="topMenuDropdown" (mouseleave)="topDropdownLeave('projectsDropdown')">
    <button class="topMenuDropdownButton" routerLink="projects/Ciripitor">Ciripitor</button>
    <button class="topMenuDropdownButton" routerLink="projects/tabel-adr">Tabel ADR</button>
    <!--<button class="topMenuDropdownButton" routerLink="projects/SAMPServer">SA:MP Server</button>-->
  </div>
  </div>
</div>
