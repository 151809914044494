import { Component, OnInit } from '@angular/core';
import {languageService} from '../../language/language.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Project, ProjectsService} from '../../services/projects.service';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  lang = languageService.language;
  projectsService: any = new ProjectsService('../assets/projects.json');
  constructor(route: ActivatedRoute, router: Router)
  {

  }

  ngOnInit(): void {
    languageService.languageEmitter.subscribe((param: string) =>
    {
      this.lang = param;
    });
  }

  loadProjects(): Array<Project>
  {
    return this.projectsService.getProjects();
  }

  loadMinorProjects(): Array<Project>
  {
    return this.projectsService.getMinorProjects();
  }
}
