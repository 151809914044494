import {Component, Input, OnInit} from '@angular/core';
import {languageService} from '../../language/language.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ISectionable} from '../../domain/interfaces/isectionable';

@Component({
  selector: 'app-aboutme',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.css']
})
export class AboutMeComponent implements OnInit, ISectionable {
  @Input()
  language = languageService.language;

  constructor(private route: ActivatedRoute, private router: Router) { }

  gotoSection(section: string): void {
        this.router.navigate(['/aboutme', section]);
    }

  ngOnInit(): void {
    languageService.languageEmitter.subscribe((param: string) => {
      this.language = param;
    });
  }

}
