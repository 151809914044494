<ng-template [ngIf]="lang==='ro'">
  <h1>
    Tabel ADR
  </h1>
</ng-template>
<ng-template [ngIf]="lang==='en'">
  <h1>
    ADR Table
  </h1>
</ng-template>

<ng-template [ngIf]="lang==='ro'">
  <p>ADR (de la <i>Accord relatif au transport international des marchandises Dangereuses par Route</i>) este un tratat internațional care specifică care substanțe sunt considerate periculoase, le categorizează după tipul de pericol pe care îl reprezintă, specifică cum ar trebui manipulate și nu în ultimul rând, cum ar trebui transportate rutier.</p>
  <p>Mai multe informații oferă articolul de pe <a href="https://en.wikipedia.org/wiki/ADR_(treaty)">Wikipedia</a> corespunzător.</p>
  <p>O aplicație care să poată calcula parametrii unui astfel de transport este utilă, deoarece permite verificarea mai ușoară și mai sigură a: restricțiilor de tunel, a necesității de coborâre a plăcuțelor negre sau portocalii care avertizează celorlalți participanți la trafic privind substanțele transportate, a interdicțiilor de transportare la comun a substanțelor, și nu numai.</p>
  <p>Astfel de norme sunt impuse pentru a preveni accidente grave pe șoselele lumii, precum <a href="https://ro.wikipedia.org/wiki/Explozia_de_la_Mih%C4%83ile%C8%99ti">explozia de la Mihăilești</a>.</p>
  <p>Această aplicație este făcută cu ajutorul de specialitate al tatălui meu și are rolul de a-l ajuta în verificarea suplimentară a substanțelor pe care le transportă.</p>
  <p>Înainte de a vorbi despre aplicație, aș dori să atrag atenția asupra unui fapt important. Singurele metode de a găsi tabelul oficial ADR sunt: cumpărând cartea ADR (dezavantajul fiind formatul fizic, care împiedică căutarea rapidă a substanțelor), tabel PDF pus la dispoziție de ARR (care nu are toate coloanele), sau contracost din părți terțe.
  În opinia mea, aceste informații ar trebui să fie puse la dispoziție de autorități și în formate mai ușor de gestionat, atât de utilizatori, cât și de calculatoare. O bază de date, un document Excel, sau măcar un fișier text aranjat.</p>
  <h2>Sumar</h2>
</ng-template>
<ng-template [ngIf]="lang==='en'">
  <p>ADR (from <i>Accord relatif au transport international des marchandises Dangereuses par Route</i>) is an international treaty that specifies what substances are dangerous, categorises them by their danger, specifies how they should be manipulated and last but not least, how they should be transported by road. </p>
  <p>More information is available on corresponding <a href="https://en.wikipedia.org/wiki/ADR_(treaty)">Wikipedia</a> article.</p>
  <p>An app that can calculate these parameters of such transports is useful, because it allows an easier and safer check of: tunnel restrictions, whether you need to show black/white or orange plates that warns other drivers about the caried goods, about goods that can't be transported at the same time, and more.</p>
  <p>Such norms are imposed to prevent tragedies on world's roads, like <a href="https://en.wikipedia.org/wiki/Mih%C4%83ile%C8%99ti_explosion">Mihăilești explosion</a>.</p>
  <p>This app is made with speciality help from my dad and the app's role is to help him safety check the goods he's carying.</p>
  <p>Before we talk about the app, I would like to warn about an important fact. The only ways to obtain the ADR table are: buying the ADR book (it's in a physical format, that doesn't allow fast searching of the substances), the PDF table that the ARR (Romanian Road Authority) (that doesn't have all the columns), or paid from third-parties.
    In my opinion, these pieces of information should be available by authorities in formats easier to manage by users, and also by computers. A database, an Excel document or at least an ordered text file.</p>
  <h2>Summary</h2>
</ng-template>

<img src="assets/images/TabelADR/Home.png" alt="Home image"/>
<ng-template [ngIf]="lang==='ro'">
  <p>Am scris această aplicație în C++ folosind Qt deoarece până atunci, singurul framework pentru a crea interfețe grafice pe care-l cunoșteam a fost Qt. Din fericire, Qt este portabil, și am reușit să rulez aplicația și de pe Ubuntu, ea fiind creată pe Windows.</p>
  <p>Așa cum se poate vedea în imaginea de mai sus, aplicația permite 3 lucruri: modificarea substanțelor în cazul în care se updatează tabelul oficial cu substanțe, verificarea scorului ADR care dă niște informații legale și de siguranță rutieră, și o vedere per ansamblu a tabelului, care permite de asemenea căutarea substanțelor și identificarea substanțelor interzise transportului rutier.</p>
  <p>Interfața grafică este flexibilă, deoarece folosește exclusiv containere flexibile: VBoxLayout, HBoxLayout și GridLayout</p>
</ng-template>

<ng-template [ngIf]="lang==='en'">
  <p>I wrote this app in C++ using Qt framework, because it was the only GUI framework I knew. Fortunately, Qt is portable, and I managed to run the app from Ubuntu, even though I wrote the app in Windows.</p>
  <p>As you can see from the image above, the app lets you do 3 things: modifying the substances when the official table is updated, calculating the ADR score that gives some legal and safety info, and an overview of the table that allows the user to search substances and checking whether a substance is forbidden from road transportation.</p>
  <p>The GUI is responsive, because it uses only flexible containers: VBoxLayout, HBoxLayout and GridLayout</p>
</ng-template>

<span>
  <img src="assets/images/TabelADR/All%20dangerous%20goods.png" alt="Tabelul tuturor substanțelor periculoase"/>
  <img src="assets/images/TabelADR/Found%20substance.png" alt="Substanta gasita"/>
</span>
<ng-template [ngIf]="lang==='ro'">
  <h2>
    Modifying substances
  </h2>
  <p>Below you can see the menu to modify substances.
    The user can search substances by their UN code or it can select the UN code from the corresponding combobox.
    The interface doesn't allow the user to enter the other parameters if the substance is marked as forbidden from road transportation. </p>
</ng-template>
<span>
  <img src="assets/images/TabelADR/Alter%20substance%20menu.png" alt="Meniu modifica substanta"/>
  <img src="assets/images/TabelADR/Alter%20substance%20menu%20-%20found%20substance%20is%20forbidden%20to%20transport.png" alt="Meniu modifica substanta - substanta interzisa"/>
</span>
<ng-template [ngIf]="lang==='ro'">
  <h2>Calcularea parametrilor</h2>
  <p>Din această fereastră se pot efectua calculele privind parametrii care determină transportabilitatea unei substanțe.</p>
  <p>Ce semnifică acești parametri? Pe scurt, să transporți 10 tone de lac de unghii, care este inflamabil, în cisternă, este mult mai periculos decât dacă le transporți în sticluțe de 100ml, deoarece incendiul poate fi mai ușor izolat, iar ambalajul este mai puțin inflamabil decât substanța din interiorul ei.
  Tratatul ADR furnizează mai multe formule de calcul, dar în acest stadiu, aplicația obține doar acești parametri.
    Un scor mai mare decât 1000 sau o cantitate limitată mai mare de 8 tone (8000 kg) indică un transport ADR.
  Prioritatea este dată de scor.
  Anume, dacă scorul este mai mare de 1000, transportul este ADR.
  Altfel, dacă cantitatea limitată este mai mare de 8000kg, dar scorul nu este peste 1000, transportul este ADR.</p>
  <p>Aplicația furnizează mai jos și acțiunile pe care trebuie să le facă șoferul.</p>
</ng-template>
<ng-template [ngIf]="lang==='en'">
  <h2>Calculating the parameters</h2>
  <p>From this window you can make the calculations regarding the aforementioned parameters that determine the transportability of a substance.</p>
  <p>What these parameters mean? In a nutshell, to carry around 10t of nail polish, which is inflammable, in a tank, is far more dangerous than transporting it in 100ml bottles, because the fire is easier to isolate, and the packaging is less inflamable than the content itself.
    ADR treaty supplies more formulas, but at the moment, the app computes only those parameters.
    A score bigger than 1000 or a limited quantity bigger than 8t (8000kg) means that a transport falls under the ADR.
    The priority is the score.
    Thus, if the score is bigger than 1000, the transport is ADR.
    Otherwise, if the limited quantity is over 8000kg, but the score is under 1000, the transport is still ADR.</p>
  <p>The apps also tells what should the driver do.</p>
</ng-template>
<span>
  <img src="assets/images/TabelADR/Computed%20scores%20-%20only%20limited%20quantities.png" alt="Calcularea scorului - avertizare cantitate limitata"/>
  <img src="assets/images/TabelADR/Computed%20scores%20-%20only%20score.png" alt="Calcularea scorului - avertizare scor"/>
  <img src="assets/images/TabelADR/Computed%20scores%20-%20score%20and%20limited%20quantity.png" alt="Calcularea scorului - ambele"/>
  <img src="assets/images/TabelADR/Computed%20scores%20-%20forbidden%20substances.png" alt="Calcularea scorului - substanta interzisa"/>
</span>
<ng-template [ngIf]="lang==='ro'">
  <h2>Persistența datelor</h2>
  <p>Datele sunt salvate într-un fișier text. Fiecărei linii îi corespunde o substanță, iar pe linie informațiile despre substanță, într-o anumită ordine.
  Îmi propun pe viitor să portez aplicația pe Android, și nu îmi doresc bătăi de cap cu librării pentru baze de date în C++.
  Arhitectura aplicației îmi permite să modific acest lucru ușor, astfel că pot oricând să modific felul în care stochez datele.</p>
</ng-template>
<ng-template [ngIf]="lang==='en'">
  <h2>Data persistence</h2>
  <p>The data is saved in a text file. Each line is a substance, and each line contains the info about the substance in a specific order.
    I intend to port the app to Android, and I don't want hassle while using C++ database libs.
    The architecture allows me to modify this easily, thus I can modify the way I store my data anytime.</p>
</ng-template>
