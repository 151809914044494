import { Component, OnInit } from '@angular/core';
import {RouterModule} from '@angular/router';
import {PersonalInfo} from '../domain/contact-info';
import {petruInfo} from './personalInfo';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})

export class InfoComponent implements OnInit {

  myself: PersonalInfo = petruInfo;

  constructor() {
  }

  ngOnInit(): void {
  }

}
